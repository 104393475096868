import { Doughnut } from 'vue-chartjs';
import { COLOR_PALETTE } from '@/settings/Charts';

export default {
	extends: Doughnut,
	data() {
		return {
			data: {
				labels: [],
				datasets: [],
			},
			options: {
				hoverBorderWidth: 20,
				responsive: true,
				maintainAspectRatio: false,
				tooltips: {
					callbacks: {
						label: (tooltipItem, data) => {
							let label = data.labels[tooltipItem.index];
							let val = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

							if (data.texts && data.texts.length > 0) {
								val = data.texts[tooltipItem.index];
							}

							if (label) {
								label += `: ${val}`;
							}
							return `${this.labelPrepend} ${label} ${this.labelAppend}`;
						},
					},
				},
			},
		};
	},
	props: {
		dataImport: {
			type: Array,
			default() {
				return [];
			},

		},
		labelAppend: {
			type: String,
			default() {
				return '';
			},
		},
		labelPrepend: {
			type: String,
			default() {
				return '';
			},
		},
		cutoutPercentage: {
			type: Number,
			default() {
				return 0; // 50 for doughnut, 0 for pie
			},
		},
		colors: {
			type: Array,
			default() {
				return COLOR_PALETTE;
			},
		},
		// Check the docs for more options
		// https://www.chartjs.org/docs/2.9.4/general/options.html
		chartOptions: {
			type: Object,
			default: () => ({}),
		},
	},
	watch: {
		chartOptions: {
			handler() {
				this.renderChart(this.data, { ...this.options, ...this.chartOptions });
			},
			deep: true,
		},
	},
	mounted() {
		this.data.labels = this.graphLabels;
		this.data.texts = this.graphText;
		this.options.cutoutPercentage = this.cutoutPercentage;
		this.fillData();
		this.renderChart(this.data, { ...this.options, ...this.chartOptions });
	},
	computed: {
		graphData() {
			const data = [];
			this.dataImport.forEach((element) => {
				data.push(element.val);
			});
			return data;
		},
		graphLabels() {
			const data = [];
			this.dataImport.forEach((element) => {
				data.push(element.trans);
			});
			return data;
		},
		graphText() {
			const data = [];
			this.dataImport.forEach((element) => {
				data.push(element.text);
			});
			return data;
		},
	},
	methods: {
		fillData() {
			const newData = {
				backgroundColor: this.colors,
				data: this.graphData,
			};
			this.data.datasets.push({ ...newData });
		},

	},
};
