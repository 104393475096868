<template>
	<div>
		<!-- Filters Start -->
		<div class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
				<div class="btn btn-lg">
					{{ datePeriod }}
				</div>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<template
						v-if="isAdminOrCorporate">
						<div
							class="col-md">
							<search
								v-model="filters.uid"
								:label-text="translate('autocomplete_label')"
								:endpoint="endpoint"
								:init-value="filters.uid"
								:empty-result-message="translate('data_not_found')"
								:placeholder="'autocomplete_placeholder'"
								:default-params="{role:'distributor'}"
								:custom-error="hasError('uid') ? errors.errors['uid'][0] : false"
								class="mb-3"
								q="label"
								return-key="id" />
						</div>
					</template>

					<div class="col-md">
						<div
							class="form-group">
							<label
								for="dateRange"
								class="label">{{ translate('date_range') }}</label>
							<select
								id="dateRange"
								v-model="dateRange"
								name="dateRange"
								class="form-control">
								<option
									v-for="dateRangeOption in dateRanges"
									:key="dateRangeOption"
									:value="dateRangeOption">
									{{ translate(dateRangeOption) }}
								</option>
							</select>
						</div>
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						class="col-md">
						<label
							for="startDate"
							class="label">{{ translate('start_date') }}</label>
						<v-date-picker
							id="startDate"
							v-model="startDate"
							:input-props="{ class: 'form-control' }"
							:formats="calendarConfig"
							name="startDate"
							show-caps />
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						class="col-md">
						<label
							for="endDate"
							class="label">{{ translate('end_date') }}</label>
						<v-date-picker
							id="endate"
							v-model="endDate"
							:input-props="{ class: 'form-control' }"
							:formats="calendarConfig"
							name="endDate"
							show-caps />
					</div>
				</div>
			</template>
		</data-filter>
		<!-- Filters End -->
		<b-row>
			<b-col>
				<div class="table-responsive">
					<table class="table table-hover table-striped text-nowrap">
						<thead>
							<tr>
								<th class="border-top-0">
									{{ translate('distance') }}
								</th>
								<!-- <th class="border-top-0">
									{{ translate('total_distributors') }}
								</th> -->
								<th
									class="border-top-0 text-right">
									{{ translate('total_sales') }}
								</th>
								<th
									class="border-top-0 text-right">
									{{ translate('duties') }}
								</th>
								<th
									class="border-top-0 text-right">
									{{ translate('total_refunds') }}
								</th>
								<th
									class="border-top-0 text-right">
									{{ translate('total_net_sales') }}
								</th>
								<th class="border-top-0">
									{{ translate('total_volume_left') }}
								</th>
								<th class="border-top-0">
									{{ translate('total_volume_right') }}
								</th>
							</tr>
						</thead>
						<tbody v-if="!loading && hasData">
							<tr
								v-for="(item, index) in data"
								:key="index">
								<td class="align-middle">
									{{ getDistance(item.attributes.distance) }}
								</td>
								<!-- <td class="align-middle">
									{{ item.attributes.total_distributors }}
								</td> -->
								<td
									class="align-middle text-right">
									{{ item.attributes.total_sales }}
								</td>
								<td
									class="align-middle text-right">
									{{ item.attributes.total_taxes }}
								</td>
								<td
									class="align-middle text-right">
									{{ item.attributes.total_refunds }}
								</td>
								<td
									class="align-middle text-right">
									{{ item.attributes.total_net_sales }}
								</td>
								<td class="align-middle">
									{{ item.attributes.total_volume_left }}
								</td>
								<td class="align-middle">
									{{ item.attributes.total_volume_right }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</b-col>
		</b-row>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />

		<template v-if="!loading && chartHasData">
			<h5 class="text-center text-muted">
				{{ translate('total_volume_by_distance') }}
			</h5>

			<pie-chart
				:height="200"
				label-append="BV"
				:data-import="chartData" />
		</template>
	</div>
</template>

<script>
import FiltersParams from '@/mixins/FiltersParams';
import { admin } from '@/settings/Roles';
import DataFilter from '@/components/DataFilter';
import UnilevelSummary from '@/util/UnilevelSummary';
import {
	custom,
	DATE_TIME_RANGES,
	DATE_RANGES,
	V_CALENDAR_CONFIG as calendarConfig,
	MDY_FORMAT,
} from '@/settings/Dates';
import {
	Network, Grids,
} from '@/translations';
import PieChart from '@/components/PieChart';
import { SEARCH_USERS } from '@/config/endpoint';

const { endpoint } = SEARCH_USERS;

export default {
	name: 'UnilevelSummaryDetails',
	components: {
		DataFilter,
		PieChart,
	},
	messages: [Network, Grids],
	mixins: [FiltersParams],
	data() {
		return {
			unilevelSummary: new UnilevelSummary(),
			dateRanges: { ...DATE_RANGES, custom },
			dateRange: DATE_RANGES.this_month,
			calendarConfig,
			admin,
			custom,
			rangeStart: '',
			rangeEnd: '',
			endpoint,
		};
	},
	computed: {
		loading() {
			try {
				return !!this.unilevelSummary.data.loading;
			} catch (error) {
				return false;
			}
		},
		errors() {
			return this.unilevelSummary.data.errors;
		},
		data() {
			try {
				const { data } = this.unilevelSummary.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			try {
				const response = this.data.length;
				return !!response;
			} catch (error) {
				return false;
			}
		},
		username() {
			try {
				return this.unilevelSummary.data.response.data.meta.username;
			} catch (error) {
				return this.$user.details().type;
			}
		},
		isAdminOrCorporate() {
			return this.admin.includes(this.$user.details().type);
		},
		datePeriod() {
			return this.getDatePeriod();
		},
		chartData() {
			const data = this.data.map((x) => {
				const obj = { text: x.attributes.total_volume, val: x.attributes.total_volume_value, trans: this.translate('level_num', { num: x.attributes.distance }) };
				if (this.isMaxDistance(x.attributes.distance)) {
					obj.trans += '+';
				}
				return obj;
			});
			return data;
		},
		chartHasData() {
			const data = this.chartData.filter((x) => x.val > 0);
			return data.length > 0;
		},
	},
	watch: {
		username(user) {
			this.$parent.$parent.$parent.$parent.titleParam = { user };
		},
	},
	mounted() {
		if (this.isAdminOrCorporate) {
			this.dateRanges = { ...DATE_TIME_RANGES, custom };
		}
		const { query } = this.$route;
		this.getSummary(query);
	},
	methods: {
		getSummary(query) {
			this.unilevelSummary.getDetails(query).then(() => {
				const { start, end } = this.unilevelSummary.data.response.data.meta.period_dates;
				this.rangeStart = this.$moment(start.date).format(MDY_FORMAT);
				this.rangeEnd = this.$moment(end.date).format(MDY_FORMAT);
			});
		},
		getDataFiltered() {
			const { query } = this.$route;
			const options = { ...query, ...this.filters };

			Object.keys(options).forEach((item) => {
				if (!options[item]) {
					delete options[item];
				}
			});

			this.getSummary(options);

			this.$router.push({ name: this.$route.name, query: options });
		},
		hasError(field) {
			if (typeof this.errors.errors[field] !== 'undefined') { return true; }
			return false;
		},
		getDistance(distance) {
			if (distance === null) this.translate('all');
			if (this.isMaxDistance(distance)) return `${distance}+`;

			return distance;
		},
		isMaxDistance(distance) {
			return distance === this.unilevelSummary.data.response.data.meta.max_distance;
		},
		getDatePeriod() {
			const start = this.rangeStart;
			const end = this.rangeEnd;
			if (start !== '' && end !== '') {
				return this.translate('date_period', { start, end });
			}

			if (start !== '') {
				return this.translate('date_period_from', { start });
			}

			if (end !== '') {
				return this.translate('date_period_until', { end });
			}
			return '';
		},
	},
};
</script>
